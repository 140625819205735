import React from 'react';
import PropTypes from 'prop-types';

const InputField = ({ type, placeholder, value, onChange, className, hasError }) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`w-full px-4 h-14 text-lg bg-[#F4F4F4] text-[#0E0F0C] rounded-lg 
      placeholder:text-[#C5C5C5] placeholder:font-archivo focus:outline-none 
       ${hasError ? 'border-2 border-[#D80027] focus:ring-[#D80027]' : ''} ${className}`}
    />
  );
};

// ✅ PropTypes Validation
InputField.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  hasError: PropTypes.bool, // ✅ Added prop for error styling
};

export default InputField;
