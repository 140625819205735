import React from 'react';
import PricingItem from './PricingItem';

const PricingCard = () => {
  return (
    <div className="bg-[#FFD964] p-6 rounded-lg shadow-lg relative max-w-md">
      {/* Blue Decorative Corner */}
      <div className="absolute top-0 right-0 w-14 h-14 bg-blue-800 rounded-bl-full"></div>

      {/* Card Title */}
      <h3 className="text-4xl font-bold text-[#0344DC] font-cabinet">Account fees</h3>

      {/* Pricing Items */}
      <div className="mt-4 space-y-3">
        <PricingItem title="Account opening" amount="0 GBP" />
        <PricingItem 
          title="Monthly account fee" 
          amount="300 GBP/Month" 
          note="(price may vary depending on business risk)"
        />
        <PricingItem title="Transaction fees" amount="0 GBP" />
      </div>
    </div>
  );
};

export default PricingCard;
