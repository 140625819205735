import React, { useState } from 'react'; 
import PropTypes from 'prop-types';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import Modal from '../../components/ui/Modal';
import InputField from '../signin/InputField';

// Business Types
const businessTypes = [
  { label: 'Sole Trader', value: 'sole_trader' },
  { label: 'Limited Liability', value: 'limited_liability' },
  { label: 'Partnership', value: 'partnership' },
  { label: 'Non-Governmental Organisation', value: 'ngo' },
];

const SignUpForm = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [businessType, setBusinessType] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [country, setCountry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const countries = countryList().getData();
  const [showVerificationStatus, setShowVerificationStatus] = useState(false);
  const [identificationFile, setIdentificationFile] = useState(null);
  const [addressFile, setAddressFile] = useState(null);

  // Custom Styles for react-select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#F4F4F4',
      borderRadius: '8px',
      padding: '5px',
      height: '56px',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: '1rem',
      fontFamily: 'Archivo, sans-serif',
      color: '#0E0F0C',
      border:'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#0344DC' : 'white', 
      color: state.isFocused ? 'white' : '#646464', 
      fontWeight: state.isFocused ? 'bold' : 'normal',
      borderBottom: '1px solid #C1C1C1',
    }),
  };

  // Simulate API Call (Replace with actual API request)
  const simulateApiCall = (success = true, delay = 1000) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        success ? resolve() : reject('An error occurred');
      }, delay);
    });
  };

  // Handle Next Step
  const handleNextStep = async () => {
    setLoading(true);
    setError(null);
    try {
      await simulateApiCall(true);
      setStep((prev) => prev + 1);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };



  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file.name);
    }
  };
  

  return (
    <Modal isOpen={isOpen} onClose={onClose} onBack={() => setStep((prev) => prev - 1)} showBack={step > 1}>
      <div className="w-full max-w-2xl mx-auto text-center overflow-y-auto max-h-[85vh] px-6">
      {/* Only render heading for steps that are NOT 6 or 7 */}
{![6, 7].includes(step) && (
  <h2 className="text-center text-4xl font-cabinet font-semibold text-[#0E0F0C] mb-4">
    {step === 1 ? 'Sign Up' :
     step === 2 ? 'Verify Phone Number' :
     step === 3 ? 'Verify Email ID' : 
     step === 4 ? 'Setup PIN' :
     step === 5 ? 'Upload Documents' :
     step === 6 ? 'Account Created Successfully' :
     step === 7 ? 'Unsuccessful Account Creation' :
     step === 8 ? 'Account Verification Status' : ''} 
  </h2>
)}



        {/* STEP 1: Sign Up */}
        {step === 1 && (
          <>
            <p className="text-center text-[#323232] mb-6">Provide your details to set up your account.</p>
            <form className="text-left font-archivo">
              <label className="block text-md font-medium text-[#0E0F0C]">Business Name</label>
              <div className="relative w-full mb-4">
      <input
        type="text"
        placeholder="Name"
        className="w-full px-4 h-14 text-lg bg-[#F4F4F4] text-[#0E0F0C] rounded-lg pr-20 
        focus:outline-none focus:ring-0 focus:border-transparent"
        value={businessName}
        onChange={(e) => setBusinessName(e.target.value)}
      />
      <span
        className={`absolute top-1/2 right-2 transform -translate-y-1/2 text-lg font-medium ${
          businessName.trim() ? 'text-[#0344DC] cursor-pointer' : 'text-[#9B9B9B]'
        }`}
      >
        Verify
      </span>
    </div>
              <label className="block text-md font-medium text-[#0E0F0C]">Business Type</label>
              <Select options={businessTypes} value={businessType} onChange={setBusinessType} placeholder="Choose Business Type" styles={customStyles} className="mb-4 h-14" />

              <label className="block text-md font-medium text-[#0E0F0C]">Business Activity</label>
              <InputField type="text" placeholder="Describe your business activity" className='mb-4'/>

              <label className="block text-md font-medium text-[#0E0F0C]">Primary Country of Residence</label>
              <Select 
  options={countries.map(c => ({
    label: (
      <div className="flex items-center">
        <img 
          src={`https://flagcdn.com/w40/${c.value.toLowerCase()}.png`} 
          alt={c.label} 
          className="w-5 h-3 mr-2"
        />
        {c.label}
      </div>
    ),
    value: c.value
  }))} 
  value={country}
  onChange={setCountry}
  placeholder="Select Country"
  styles={customStyles}
  className="mb-4"
/>




              <label className="block text-md font-medium text-[#0E0F0C]">Email Address</label>
              <InputField type="email" placeholder="Enter Email Address" className='mb-4' />

              <label className="block text-md font-medium text-[#0E0F0C]">Phone Number</label>
              <InputField type="tel" placeholder="Enter Phone Number" className='mb-4' />

              {/* Next Button */}
              <button onClick={handleNextStep} type="button" className="w-full mt-4 bg-[#0344DC] text-white h-14 rounded-lg text-lg font-semibold hover:bg-blue-700" disabled={loading}>
                {loading ? 'Processing...' : 'Next'}
              </button>
              {/* ✅ Login Link Restored */}
        <div className="text-center mt-6 text-sm font-semibold text-[#0E0F0C]">
          Already have an account?{' '}
          <a href="#" className="text-[#0344DC] font-semibold underline">Login</a>
        </div>
            </form>
          </>
        )}


        {/* STEP 2: Phone Verification */}
        {step === 2 && (
          <>
            <p className="text-center font-archivo font-medium text-[#0E0F0C] mb-6">
              Enter 4-digit code sent to your phone. <strong>(Valid for 60 seconds)</strong>
              <a href="#" className="text-blue-600 font-[#0344DC] ml-2 underline">Change phone number</a>
            </p>
            <div className='text-left'>
            <label className="block text-md font-archivo font-semibold text-[#0E0F0C] pb-4">4-digit verification code</label>
            <InputField type="text" placeholder="Enter verification code" className='mb-4' />
            </div>

            <div className="text-center mt-8 text-sm font-archivo">
          <a href="#" className="text-[#0344DC] font-semibold underline">Resend code</a>
        </div>
            
            <button onClick={handleNextStep} className="w-full mt-4 bg-[#0344DC] text-white h-14 rounded-lg text-lg font-semibold hover:bg-blue-700" disabled={loading}>
              {loading ? 'Verifying...' : 'Submit'}
            </button>
          </>
        )}

        {/* STEP 3: Verify Email ID */}
{step === 3 && (
  <>
    <p className="text-center font-archivo font-medium text-[#0E0F0C] mb-6">
      Enter the 4-digit code we have sent to <strong>your email</strong>.
      <strong> (Valid for 60 seconds) </strong>
      <a href="#" className="text-blue-600 font-[#0344DC] ml-2 underline">
        Change email address
      </a>
    </p>

    <div className='text-left'>
      <label className="block text-md font-archivo font-semibold text-[#0E0F0C] pb-4">
        4-digit verification code
      </label>
      <InputField type="text" placeholder="Enter verification code" className='mb-4' />
    </div>

    <div className="text-center mt-8 text-sm font-archivo">
      <a href="#" className="text-[#0344DC] font-semibold underline">Resend code</a>
    </div>

    <button
      onClick={handleNextStep}
      className="w-full mt-4 bg-[#0344DC] text-white h-14 rounded-lg text-lg font-semibold hover:bg-blue-700"
      disabled={loading}
    >
      {loading ? 'Verifying...' : 'Submit'}
    </button>
  </>
)}


        {/* STEP 4: Setup PIN */}
{step === 4 && (
  <>
    <p className="text-center text-[#0E0F0C] font-semibold font-archivo mb-4 pt-8">
      Create PIN
    </p>

    <div className="flex justify-center space-x-4 mb-4">
      {Array(6).fill("").map((_, i) => (
        <input
          key={i}
          type="password"
          maxLength="1"
          className="w-12 h-12 text-center text-lg font-semibold border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
      ))}
    </div>

    <p className="text-center text-[#0E0F0C] font-semibold  font-archivo mb-4">Confirm PIN</p>

    <div className="flex justify-center space-x-4 mb-4">
      {Array(6).fill("").map((_, i) => (
        <input
          key={i}
          type="password"
          maxLength="1"
          className="w-12 h-12 text-center text-lg font-semibold border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
      ))}
    </div>

    <button
      onClick={handleNextStep}
      className="w-full bg-[#0344DC] text-white h-14 mt-6 font-archivo rounded-lg text-lg font-semibold hover:bg-blue-700"
      disabled={loading}
    >
      {loading ? 'Setting up PIN...' : 'Create PIN'}
    </button>
  </>
)}

{step === 5 && (
        <>
          <p className="text-left text-[#323232] text-lg font-semibold font-archivo mb-3 mt-8">
            Shareholder with significant control:
          </p>

          {/* File Upload Sections */}
          <div className="space-y-4 font-archivo">
            {/* Proof of Identification */}
            <label className="border border-dashed border-[#0344DC] bg-[#F4F4F4] rounded-lg p-6 text-center cursor-pointer block">
              <input
                type="file"
                accept="image/jpeg, image/png"
                className="hidden"
                onChange={(e) => handleFileChange(e, setIdentificationFile)}
              />
              <img className="mb-2" style={{ display: "unset" }} src="/upload-icon.png" />
              {identificationFile && (
                <p className="text-[#0344DC] font-semibold">{identificationFile}</p>
              )}
              <p className="text-[#29304D] font-base mb-2">
                Upload Proof Of Identification (Valid Passport Or Driver License)
              </p>
              <p className="text-[#0344DC] font-semibold underline cursor-pointer">
                JPEG & PNG
              </p>
            </label>

            {/* Proof of Address */}
            <label className="border border-dashed border-[#0344DC] bg-[#F4F4F4] rounded-lg p-6 text-center cursor-pointer block">
              <input
                type="file"
                accept="image/jpeg, image/png"
                className="hidden"
                onChange={(e) => handleFileChange(e, setAddressFile)}
              />
              <img className="mb-2" style={{ display: "unset" }} src="/upload-icon.png" />
              {addressFile && <p className="text-[#0344DC] font-semibold">{addressFile}</p>}
              <p className="text-[#29304D] font-base mb-2">
                Upload Proof Of Address (Utility Bills Or Bank Statement Or Driver License)
              </p>
              <p className="text-[#0344DC] font-semibold underline cursor-pointer">
                JPEG & PNG
              </p>
            </label>

            {/* Liveness Check Verification */}
            <div className="border border-dashed border-[#0344DC] bg-[#F4F4F4] rounded-lg p-6 text-center">
              <img className="mb-2" style={{ display: "unset" }} src="/face-icon.png" />
              <p className="text-[#29304D] font-base mb-2">
                Complete liveness check verification.{" "}
                <span className="font-semibold">
                  Note: You will be taken to a third-party website.
                </span>
              </p>
              <div className="flex items-center justify-center space-x-4 mt-2">
                <button className="bg-white font-bold text-[#0344DC] px-4 py-2 rounded-lg">Mobile</button>
                <span className="font-base text-[#29304D]">Or</span>
                <button className="bg-white font-bold text-[#0344DC] px-4 py-2 rounded-lg">Computer</button>
              </div>
            </div>
          </div>

          {/* Next Button */}
          <button
            onClick={() => setStep((prev) => prev + 1)}
            className="w-full mt-6 bg-[#0344DC] text-white h-14 rounded-lg text-lg font-semibold hover:bg-blue-700"
            disabled={loading}
          >
            {loading ? "Uploading..." : "Next"}
          </button>
        </>
      )}


        {/* STEP 6: Account Created Successfully */}
        {step === 6 && !showVerificationStatus && (
          <>
            <img className="mb-2" style={{display:'unset'}} src="/rounded-tick.png" />
            <p className="text-center text-4xl font-cabinet font-semibold text-[#0E0F0C] mb-4">
      Account Created Successfully
    </p>
            <p className="text-center text-[#323232] mb-6">Your account has been created successfully.</p>
            <button
      onClick={() => setShowVerificationStatus(true)}
      className="w-full mb-4 border border-[#0344DC] text-[#0344DC] py-4 rounded-lg text-lg font-semibold"
    >
      View Verification Status
    </button>
            <button onClick={onClose} className="w-full bg-[#0344DC] text-white h-14 rounded-lg text-lg font-semibold hover:bg-blue-700">
              Close
            </button>
          </>
        )}

        {/* STEP 7: Unsuccessful Account Creation */}
        {step === 7 && !showVerificationStatus && (
          <>
           <img className="mb-2" style={{display:'unset'}} src="/rounded-cross.png" />
           <p className="text-center text-4xl font-cabinet font-semibold text-[#0E0F0C] mb-4">
           Unsuccessful account creation
    </p>
            <p className="text-center text-[#0E0F0C] mb-6">We are sorry but on this occasion we are unable to issue you on account as we could not complete verification. Contact us at support@JPXhub.com</p>
            <button
      onClick={() => setShowVerificationStatus(true)}
      className="w-full mb-4 border border-[#0344DC] text-[#0344DC] py-4 rounded-lg text-lg font-semibold"
    >
      View Verification Status
    </button>
            <button onClick={onClose} className="w-full bg-[#0344DC] text-white h-14 rounded-lg text-lg font-semibold">
              Close
            </button>
          </>
        )}

       
{showVerificationStatus && (
  <div className="text-center max-w-lg mx-auto">
    <h2 className="text-center text-4xl font-cabinet font-semibold text-[#0E0F0C] mb-4">
      Account Verification Status
    </h2>
    <p className="text-left text-[#0E0F0C] font-semibold font-archivo mb-6" style={{paddingLeft:'48px'}}>
      Please note it could take up to 24 hours to issue your account.
    </p>

    {/* Status Steps */}
    <div className="flex flex-col items-start space-y-16 relative ml-12">
      
      {/* ID Verification */}
      <div className="relative flex items-center">
        {/* Circle */}
        <div className="relative w-8 h-8 flex items-center justify-center">
          <div className="w-5 h-5 bg-[#4D9022] rounded-full"></div>
          <div className="absolute w-8 h-8 border-2 border-[#4D9022] rounded-full"></div>
        </div>
        <span className="text-lg text-[#0E0F0C] font-semibold ml-4">ID Verification</span>
        {/* Dotted Line (Spacing Fixed) */}
        <div style={{height:'2.5rem'}} className="absolute w-[2px] border-l-2 border-dashed border-[#EB1C24] left-4 mt-2 mb-2 top-1/2 translate-y-1/2"></div>
      </div>

      {/* Proof Of Address Verification */}
      <div className="relative flex items-center">
        {/* Circle */}
        <div className="relative w-8 h-8 flex items-center justify-center">
          <div className="w-5 h-5 bg-[#EB1C24] rounded-full"></div>
          <div className="absolute w-8 h-8 border-2 border-[#EB1C24] rounded-full"></div>
        </div>
        <span className="text-lg text-[#0E0F0C] font-semibold ml-4">Proof Of Address Verification</span>
        {/* Dotted Line (Spacing Fixed) */}
        <div style={{height:'2.5rem'}} className="absolute w-[2px] border-l-2 border-dashed border-[#FFD964] left-4 mt-2 mb-2 top-1/2 translate-y-1/2"></div>
      </div>

      {/* Liveness Check Verification (Last step - no line below) */}
      <div className="relative flex items-center">
        {/* Circle */}
        <div className="relative w-8 h-8 flex items-center justify-center">
          <div className="w-5 h-5 bg-[#FFD964] rounded-full"></div>
          <div className="absolute w-8 h-8 border-2 border-[#FFD964] rounded-full"></div>
        </div>
        <span className="text-lg text-[#0E0F0C] font-semibold ml-4">Liveness Check Verification</span>
      </div>
    </div>

    {/* Back Button */}
    <button
      onClick={() => setShowVerificationStatus(false)}
      className="w-full mt-12 bg-[#0344DC] text-white py-4 rounded-lg text-lg font-semibold hover:bg-blue-700"
    >
      Back
    </button>
  </div>
)}






        {/* Error Message */}
        {error && <p className="text-[#D80027] text-center mt-2">{error}</p>}

      </div>
    </Modal>
  );
};

SignUpForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SignUpForm;
