import React from 'react';
import PropTypes from 'prop-types';

const InputField = ({ type, placeholder }) => {
  return type === 'textarea' ? (
    <textarea
      placeholder={placeholder}
      className="w-full p-3 font-archivo bg-[#3F3F3F] text-white rounded-lg placeholder:font-normal placeholder:text-[#C5C5C5] placeholder:font-archivo focus:outline-none focus:ring-2 focus:ring-blue-600"
      rows="4"
    ></textarea>
  ) : (
    <input
      type={type}
      placeholder={placeholder}
      className="w-full p-3 font-archivo bg-[#3F3F3F] text-white rounded-lg placeholder:font-normal placeholder:text-[#C5C5C5] placeholder:font-archivo focus:outline-none focus:ring-2 focus:ring-blue-600"
    />
  );
};

// ✅ Add PropTypes validation
InputField.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default InputField;
