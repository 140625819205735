import React from 'react';
import InputField from './InputField';

const ContactForm = () => {
  return (
    <form className="bg-[#2D2D2D] space-y-4">
      <InputField type="text" placeholder="Name" />
      <InputField type="email" placeholder="Email address" />
      <InputField type="text" placeholder="Business name" />
      <InputField type="text" placeholder="Country" />
      <InputField type="textarea" placeholder="What questions do you have" />

     <div className='text-right'>
     <button className="bg-[#0344DC] text-white py-3 px-6 font-archivo rounded-lg text-base font-semibold hover:bg-blue-700">
        Submit
      </button>
     </div>
      
    </form>
  );
};

export default ContactForm;
