import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const FrontNavbar = ({ onSignInClick }) => {
  const location = useLocation();

  // Define pages that need light background & dark text
  const lightHeaderPages = ['/privacy-policy', '/cookie-policy', '/prohibited-list', '/terms-of-use'];
  const isLightMode = lightHeaderPages.includes(location.pathname);

  return (
    <header
      className={`w-full flex flex-col md:flex-row items-center justify-between md:pl-12 md:pr-12 pt-2 pb-2 transition-all duration-300 ${
        isLightMode ? 'bg-[#f5f5f5] text-[#2d2d2d]' : 'bg-[#2d2d2d] text-white'
      }`}
    >
      {/* Logo */}
      <div className="flex items-center md:mb-0">
        <img src={isLightMode ? "/sp-logo-dark.png" : "/sp-logo.png"}  alt="Sp Logo" className="mr-4" />
      </div>

      {/* Navigation */}
      <nav className="flex space-x-4 py-2 px-4 mb-2 md:mb-0">
        <NavLink to="#" className={`${isLightMode ? 'text-[#2d2d2d]' : 'text-white'} py-2 px-4`}>
          How it Works
        </NavLink>
        <NavLink to="#" className={`${isLightMode ? 'text-[#2d2d2d]' : 'text-white'} py-2 px-4`}>
          Pricing
        </NavLink>
      </nav>

      {/* Right Side */}
      <div className="flex items-center space-x-4">
        <button
          onClick={onSignInClick}
          className="py-2 px-4 rounded-full bg-custom-dark-blue text-white"
        >
          Sign in
        </button>
      </div>
    </header>
  );
};

// ✅ PropTypes Validation
FrontNavbar.propTypes = {
  onSignInClick: PropTypes.func.isRequired,
};

export default FrontNavbar;
