import React from 'react';
import PropTypes from 'prop-types';

const StepCard = ({ number, text }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md flex items-center space-x-4 w-fit mb-4">
      <div className="w-14 h-8 flex items-center justify-center bg-blue-600 text-white font-bold rounded-full">
        {number}
      </div>
      <p className="text-[#0E0F0C] font-bold font-archivo">{text}</p>
    </div>
  );
};

// ✅ Add PropTypes validation
StepCard.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
};

export default StepCard;
