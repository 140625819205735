import React from 'react';
import FrontFooterLinks from './FrontFooterLinks';

function FrontFooter() {
  return (
    <footer className="bg-[#f5f5f5] pt-8 pl-12 pr-12">
      <div className="flex flex-wrap pb-[76px] border-b border-[ #EBEBEB]">
        {/* Left Section - Branding & Description (50%) */}
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
          <img src="/logo-black.png" />
          <p className="text-[#0E0F0C] font-archivo mt-2 text-sm pr-20">
            JPX Hub (JPX) designs and operates the SP business website. JPX is not a bank nor a financially regulated institution. 
            FX Master Limited, trading as FXMaster, is a company registered in England (registration number 05248673). 
            Registered address: 30 Churchill Place, Canary Wharf, E14 5RE London, United Kingdom.
          </p>
        </div>

        {/* Middle & Right Sections - Legal & Contact Info (25% each) */}
        <FrontFooterLinks />
      </div>

      {/* Bottom Copyright Section */}
      <div className="text-center text-[#6F6F6F] font-archivo pb-[15px] text-sm mt-6">
        © 2025 sP
      </div>
    </footer>

  );
}

export default FrontFooter;