import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineClose } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io'; 

const Modal = ({ isOpen, onClose, onBack, showBack, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-full h-full flex flex-col items-center justify-center p-8 relative">
        
        {/* ✅ Back Button (Top Left) */}
        {showBack && (
          <button
            onClick={onBack}
            className="absolute top-6 left-6 flex items-center text-[#0E0F0C] hover:text-black font-base"
          >
            <IoIosArrowBack size={20} className="mr-1" />
            Back
          </button>
        )}

        {/* ❌ Close Button (Top Right) */}
        <button
          onClick={onClose}
          className="absolute top-6 right-6 text-gray-600 hover:text-black"
        >
          <AiOutlineClose size={24} />
        </button>

        {/* Modal Content */}
        <div className="w-full max-w-lg md:max-w-xl">
          {children}
        </div>
      </div>
    </div>
  );
};

// ✅ Prop Validation
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  showBack: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Modal;
