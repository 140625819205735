import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="pl-12 pr-12 bg-[#f5f5f5] font-archivo text-[#0E0F0C] pt-4 font-archivo">
        <div className="border-b border-[#EBEBEB] pb-8 ">
      <h1 className="text-5xl font-semibold font-cabinet mb-8 pt-4">Privacy Policy</h1>

      <h2 className="text-3xl font-semibold font-cabinet mb-3">1. Protecting your personal information</h2>
      <p className="mb-4">
      This policy sets out the basis on which any personal data we collect from you, or that you provide to us when you visit www.sp-business.co.uk or interact with us via mobile app or other digital service, by phone, in person, or through written forms will be processed by us. Please read the following carefully to understand how and why we are collecting or processing personal data about you.
      </p>
      <p className="mb-6">
      Personal information refers to information or opinions about, or from a person who is identified, or who can reasonably be identified from that information. We are committed to protecting and respecting your privacy and the confidentiality of the information we collect, store, and disclose. For the purpose of the Data Protection Act 1998 (the Act), the data controller is sP international Ltd (“we”, “our” and “us”), a company incorporated in England and Wales under company registration number 13466976. Our registered office is at 128 City Road, London, EC1V 2NX, United Kingdom.
      </p>

      <h2 className="text-3xl font-semibold font-cabinet mb-3">2. Why we collect information</h2>
      <p className="mb-6">
      We collect, process and store personal information for a variety of reasons, including to provide our services, provide more effective responses to requests, improvement of the services we provide, customizing your experience, maintaining security, maintaining business records for audits and reporting obligations and compliance with legal or regulatory requirements.
      </p>

      {/* Section 3 */}
      <h2 className="text-3xl font-semibold font-cabinet mb-3">3. Information we collect</h2>
      <p className="mb-4">We will collect and process the following data about you.</p>

     
      <p className="mb-4">
      a.  Information you give us. This is information about you that you give us by filling in forms on our website or mobile app or by corresponding with us by phone, e-mail or otherwise. This includes personal data, payment data and social media login data that you may provide when you apply for our services and provide feedback to us.
      </p>

      
      <p className="mb-4">
      b.  Information we collect about you. With regard to each of your visits to our website we will automatically collect the following information: i. technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your location, your login information, browser/ app type and version, device type, time zone setting, browser plug-in types and versions, operating system and platform. ii. information about your visit, including the pages on, through and from our website that your visit (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouseovers), and methods used to browse away from the page and any phone number used to call our customer service number.
</p>
<p className="mb-4">c.  Information we collect about you with your consent is shared with third parties such as Veriff to ensure that we are able to comply with your identification checks in line with our anti-money regulation and anti-terrorism regulatory requirements. Veriff is a third-party provider we use in providing online identification services. e.g., driver’s license, passport or ID card. They process your data as a data processor. You can read more about their privacy policy here at https://www.veriff.com/privacy-policy</p>

<h2 className="text-3xl font-semibold font-cabinet mb-3">4. How we use information</h2>
      <p className="mb-4">
      a. We use information held about you in the following ways: a. Information you give to us. We may use this information: i. to facilitate the account creation and logon process if you choose to link your account with us to a third-party account (such as your Google or Facebook account). ii. to carry out our obligations arising from any contracts entered between you and us and to provide you with the information and services that you request from us. iii. to provide you with information about other services we offer that are similar to those that you have already purchased or enquired about. iv. to provide you with information about goods, services, opportunities, or events that may be of interest you. v. to notify you about changes to our service. vi. to ensure that content from our website is presented in the most effective manner for you and for your computer; for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our services, products, marketing, and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent.
      </p>

      
      <p className="mb-4">
      b.   Information we collect about you. We may use this information: i. to administer our website and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes. ii. to improve our website to ensure that content is presented in the most effective manner for you and for your computer. iii. as part of our efforts to protect our business and keep our website safe and secure.

</p>
<h2 className="text-3xl font-semibold font-cabinet mb-3">5. Protecting and disclosing information</h2>
      <p className="mb-4">
      We only share personal data with trusted partners for the purpose, or related purpose it was collected. Examples include payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We will never pass on your information to a third party for marketing purposes.

</p>

      <p className="mb-4">
      If our business is sold, we will transfer your data to the purchaser because we have a legitimate interest to ensure our business can be continued by the buyer. When requested or required, we may be obliged to share your personal information in order to comply with our legal obligations or to protect the rights, property, or safety of straitPay International Ltd, our customers, or others.

</p>
      <p className="mb-4">
      We use external organizations to provide maintenance, hosting and database management services to us and some are outside the European Economic Area. Your information will only be shared with third parties who have a commitment to maintaining security and confidentiality and only in connection with our services.

</p>
<h2 className="text-3xl font-semibold font-cabinet mb-3">6. Retaining information</h2>
      <p className="mb-4">
      We will hold on to your information only for as long as necessary, after which we take reasonable steps to destroy or de-identify it.

</p>

      <p className="mb-4">
      Where you are only receiving technical or marketing information from us, we will store your contact details until you unsubscribe, or withdraw your consent to receive these communications from us. Please keep in mind that very limited details (your email address) may be retained by us in accordance with data protection law to ensure we don’t contact you again.


</p>
      <p className="mb-4">
      Where you provide information in connection with reporting a problem with our service, we will store any such information for as long as reasonably required to fix such a problem and prevent any future occurrences. Where you contact us, and we keep a record of that correspondence we will keep that for as long as is reasonably required.


</p>
      <p className="mb-4">
      We take all reasonable steps to keep your information secure with appropriate safeguards, including password protection and controlled access, however the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our website and any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.

</p>
<h2 className="text-3xl font-semibold font-cabinet mb-3">7. Access to Information</h2>
      <p className="mb-4">
      We take all reasonable steps to ensure your information is accurate and you are entitled to access the information we hold about you. Any access request will be subject to a fee of £10 to meet our costs in providing you with details of the information we hold about you. If you would like to exercise your rights under data protection law, please contact us at contact@jpxhub.com.

</p>
<h2 className="text-3xl font-semibold font-cabinet mb-3">8. Cookies</h2>
      <p className="mb-4">
      We use cookies (small files that we store on your browser or the hard drive of your computer if you agree) to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our website. By continuing to browse our website, you are agreeing to our use of cookies

</p>
      <p className="mb-4">
      
      We may use the information collected to make our website more relevant to your interests. We may also share this information with third parties for this purpose.


</p>
      <p className="mb-4">      
      We use the following types of cookies: i. Analytical/performance cookies. They allow us to recognize and count the number of visitors and to see how visitors move around our website when they are using it. ii. Essential cookies. These are cookies that are required for the operation of our website. ii. Functionality cookies. These are used to recognize you when you return to our website. iv. Targeting cookies. These cookies record your visit to our website, the pages you have visited and the links you have followed. Please note that third parties (including, for example, providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.
</p>
      <p className="mb-4">      
      You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) some parts of our website may become inaccessible or not function properly.

</p>
      <p className="mb-4">For more information on how we use cookies please read our Cookie Policy.</p>
      <h2 className="text-3xl font-semibold font-cabinet mb-3">9. Changes to our privacy policy</h2>
      <p className="mb-4">We regularly review our privacy policy to ensure it is in accordance with best practice and legislative changes. Any changes we make to our privacy policy in the future will be incorporated into a new version and made available on our website. The most recent published version will apply to how we use your personal information</p>
      <h2 className="text-3xl font-semibold font-cabinet mb-3">10. Contact</h2>
      <p className="mb-4">f you have any questions, comments or requests regarding this privacy policy please contact us at contact@jpxhub.com.. We treat all complaints seriously, and investigate all issues brought to our attention.</p>
      <p className="mb-4">Last updated: 20 December 2024</p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
