import React from 'react';
import PropTypes from 'prop-types';

const BenefitCard = ({ text }) => {
  return (
    <div className="bg-white p-3 rounded-lg shadow-md flex items-center space-x-3 w-full max-w-[350px] whitespace-nowrap">
      <div className="w-6 h-6 flex items-center justify-center bg-blue-600 text-white rounded-full">
        ✔
      </div>
      <p className="text-[#0E0F0C] font-bold font-archivo">{text}</p>
    </div>
  );
};

// ✅ Add PropTypes validation
BenefitCard.propTypes = {
  text: PropTypes.string.isRequired,
};

export default BenefitCard;
