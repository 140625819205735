import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import FrontNavbar from './FrontNavbar';
import FrontFooter from './FrontFooter';
import Modal from '../ui/Modal';
import SignInForm from '../../pages/signin/SignInForm';
import SignupForm from '../../pages/signup/SignupForm';

function FrontLayout() {
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);

  // Open & Close Handlers
  const handleOpenSignIn = () => {
    setIsSignInOpen(true);
    setIsSignUpOpen(false); // Close SignUp when opening SignIn
  };

  const handleOpenSignUp = () => {
    setIsSignUpOpen(true);
    setIsSignInOpen(false); // Close SignIn when opening SignUp
  };

  const handleCloseModals = () => {
    setIsSignInOpen(false);
    setIsSignUpOpen(false);
  };

  return (
    <div>
      <FrontNavbar onSignInClick={handleOpenSignIn} />
      <Outlet />
      <FrontFooter />

      {/* Sign In Modal */}
      <Modal isOpen={isSignInOpen} onClose={handleCloseModals}>
        <SignInForm onClose={handleCloseModals} onOpenSignUp={handleOpenSignUp} />
      </Modal>

      {/* Sign Up Modal */}
      <Modal isOpen={isSignUpOpen} onClose={handleCloseModals}>
        <SignupForm isOpen={isSignUpOpen} onClose={handleCloseModals} onOpenSignIn={handleOpenSignIn} />
      </Modal>
    </div>
  );
}

export default FrontLayout;
