import React from "react";

const ProhibitedList = () => {
  return (
      <div className="pl-12 pr-12 bg-[#f5f5f5] font-archivo text-[#0E0F0C] pt-4 font-archivo">
        <div className="border-b border-[#EBEBEB] pb-8 ">
        <h1 className="text-3xl font-semibold font-cabinet mb-8 pt-4 sm:text-4xl md:text-5xl leading-tight break-words max-w-3xl">
  PROHIBITED LIST INDUSTRIES WE CAN’T SUPPORT, NO MATTER YOUR BUSINESS TYPE
</h1>




      <h2 className="text-3xl font-semibold font-cabinet mb-3">Introduction</h2>
      <p className="mb-4">
      Certain industries have higher risks, where we need to put extra checks and controls in place. We’re currently focusing on industries that don’t need these. The list of prohibited businesses are listed below:
      </p>
      
      <p className="mb-4">

      1. Mining and quarrying. <br />
      2. Defence and weapons<br />
      3. Dating or escort service<br />
      4. Precious metals and stones (like jewellery)<br />
      5. Technology equipment, like lasers or telecommunications<br />
      6. Gambling and betting<br /> 
      7. Weapons manufacturing and/or selling<br />
      8. Unlicensed scrap metal<br />
      9. Unregistered waste management <br />
      10. Shell companies<br />
      11. Illegal or unlicensed drugs<br />
      12. Bidding fee auctions<br />
      13. Selling or making cannabidiol products (CBD), like cannabidiol oil<br />
      14. Public administration and defence, and compulsory social security. That includes things like defence, immigration services, foreign affairs and running government programmes.<br /> 
      15. Activities of extraterritorial organisations and bodies. That’s companies like the UN, involved in getting peaceful relations among nations, providing human needs and humanitarian aid, or regulating international trade<br />
      </p>
      <p className="mb-6">
      If your industry isn’t mentioned above, you can apply for a business account. But a heads up: we might not accept businesses in other industries too, if we don’t think it’s appropriate.
      </p>

      
    </div>
       </div>
  );
};

export default ProhibitedList;
