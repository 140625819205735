import React from "react";

const TermsOfUse = () => {
  return (
      <div className="pl-12 pr-12 bg-[#f5f5f5] font-archivo text-[#0E0F0C] pt-4 font-archivo">
        <div className="border-b border-[#EBEBEB] pb-8 ">
      <h1 className="text-5xl font-semibold font-cabinet mb-8 pt-4">Terms of Use</h1>

      <h2 className="text-3xl font-semibold font-cabinet mb-3">Introduction</h2>
      <p className="mb-4">
      The website www.sp.business (&quot;website&quot;) is owned and operated by JPX FZC (&quot;we&quot;, &quot;our&quot; and &quot;us&quot;), a company incorporated in the United Arab Emirates with an office in the United Kingdom at 128 City Road, London, EC1V 2NX, United Kingdom.
      </p>
      <p className="mb-6">
      These terms of use (&quot;Terms of Use&quot;) (together with the documents referred to in it) apply to the use of our website and app, regardless of how you access them. Please read these Terms of Use carefully before you proceed.
      </p>
      <p className="mb-6">
      Please also read our privacy policy (&quot;Privacy Policy&quot;) which explains how we will use any information about you that we receive, our cookie policy (&quot;Cookie Policy&quot;) and our terms and conditions (&quot;User Agreement&quot;) which governs the provision and use of stock services via our app. The Privacy Policy, Cookie Policy, User Agreement and any other additional terms and conditions or policies that may be posted on the website from time-to-time form part of these Terms of Use.
      </p>
      <p className="mb-6">
      This website and app are intended for users who are at least 18 years old. If you are under the age of 18, you are not permitted to register on the website or use any of our services without parental permission.
      </p>
      <p className="mb-6">
      We may, at any time and without notice, terminate your access to or use of this website or app. If we do so, you do not have the right to bring any claim or claims against us.
      </p>
      <p className="mb-6">
      To contact us, please email contact@jpxhub.com
      </p>

      <h2 className="text-3xl font-semibold font-cabinet mb-3">Consent to Terms of Use</h2>
      <p className="mb-6">By using this website or app you accept, and agree to abide by these Terms of Use</p>
      <p className="mb-6">If you do not agree to these Terms of Use, please do not use this website or app. These Terms of Use were last updated on the date shown at the end. We may change these Terms of Use at any time by posting an updated version on our website, so you may wish to check it before using this website or app.</p>

      {/* Section 3 */}
      <h2 className="text-3xl font-semibold font-cabinet mb-3">Prohibited uses</h2>
      <p className="mb-4">You may only use this website and app for lawful purposes. The website and app may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us. You may not use this website:</p>

     
      <p className="mb-4">
      In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect; to interfere with any other user’s use or enjoyment of the website or app; to create, check, confirm, update or amend your own or someone else’s databases, records, directories, customer lists, mailing or prospecting lists; <br />
      1. To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards (see below); <br />
      2. To circumvent, disable, or otherwise interfere with security-related features of the website or app, including features that prevent or restrict the use or copying of any content or enforce. <br />
      3. To transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or any other form of similar solicitation (spam); <br />
      4. As part of any effort to compete with us or to create a revenue-generating endeavor or commercial enterprise; <br />
      5. To decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the website or app; and/or <br />
      6. To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.
      </p>
<h2 className="text-3xl font-semibold font-cabinet mb-3">Intellectual Property Rights</h2>
      <p className="mb-4">
      Unless we expressly state otherwise, the content of the website and app (including without limitation, software, graphics, text, images, designs, compilations, databases, targeting information, and the trademarks, logos, domain names, trade names, service marks, trade identities, any and all copyright material (including source and object code), and all other materials related to the website) (the &quot;Content&quot;) is protected by applicable copyrights, trademark rights, database rights and other proprietary rights. We (and/or our licensors) own the Content. Nothing in these Terms of Use grants you a right or licence to use the Content except as expressly provided in these Terms of Use.
</p>
    
      <p className="mb-4">
      You may not use any of the material on this website without our prior written permission for your own commercial purposes, whether by reproducing, duplicating, re-selling, copying, downloading, printing, linking to, editing, broadcasting, distributing or otherwise.
</p>
      <p className="mb-4">
      Subject to strict compliance with these Terms of Use, we grant you a limited, personal, non-exclusive, non-commercial, revocable, non-assignable and non-transferrable license to use the website and to view, download for caching purposes only, and print pages from the website for your own personal non-commercial use.
</p>
<h2 className="text-3xl font-semibold font-cabinet mb-3">Registration</h2>
      <p className="mb-4">
      Certain parts of our website or app may require you to register and provide certain information about yourself and where you do this you agree to:
</p>

      <p className="mb-4">
      1. Provide true, accurate, current and complete information about yourself as prompted by the relevant registration form (the &quot;Registration Data&quot;) and

</p>
      <p className="mb-4">
      2. Maintain up-to-date Registration Data to keep it true, accurate, current and complete.
</p>
      <p className="mb-4">
      Our use of Registration Data and certain other information about you is subject to our Privacy Policy.
</p>
      <p className="mb-4">
      When you register on our website or app, you will be given a unique username and/or password that gives you access to your account. You are responsible for maintaining the confidentiality of your username and password, and are fully responsible for all activities that occur through your account. You agree to
</p>
      <p className="mb-4">
      1. Immediately notify us if you become aware of any unauthorised use of your password or website account or any other breach of security by sending an appropriately worded email to contact@jpxhub.com and
      </p>
      <p className="mb-4">2 Ensure that you exit from your account at the end of each session. We cannot and will not be liable for any loss or damage arising from your failure to comply with these requirements.
</p>
      <p className="mb-4">You represent that you are entitled to disclose your Third Party Account login information to us and/or grant us access to your Third Party Account without breach by you of any of the terms and conditions that govern your use of the applicable Third Party Account and without obligating us to pay any fees or making us subject to any usage limitations imposed by such third party service providers.
</p>
      <p className="mb-4">By granting us access to any Third Party Accounts, you understand that</p>
      <p className="mb-4">1. We may access, make available and store (if applicable) any content that you have provided to and stored in your Third Party Account (the “Social Network Content”) so that it is available on and through the Site via your account, including without limitation any friend lists; and </p> 
      <p className="mb-4">2. We may submit and receive additional information to your Third Party Account to the extent you are notified when you link your account with the Third Party Account.</p>
      <p className="mb-4">You will have the ability to disable the connection between your account on the Site and your Third-Party Accounts at any time. Please note that your relationship with the third-party service providers associated with your third-party accounts is governed solely by your agreement(s) with such third-party service providers. We make no effort to review any Social Network Content for any purpose, including but not limited to, for accuracy, legality or non-infringement, and we are not responsible for any Social Network Content.</p>
      <p className="mb-4">You acknowledge and agree that we may access your email address book associated with a Third Party Account and your contacts list stored on your mobile device or tablet computer solely for purposes of identifying and informing you of those contacts who have also registered to use the Site. At your email request to contact@jpxhub.com or through your account settings (if applicable), we will deactivate the connection between the Site and your Third Party Account and attempt to delete any information stored on our servers that was obtained through such Third Party Account, except the username and profile picture that became associated with your account.</p>

<h2 className="text-3xl font-semibold font-cabinet mb-3">Contribution standards</h2>
      <p className="mb-4">
      These contribution standards apply to any and all material (including without limitation text, images, clips, videos, comments, posts and any underlying material) uploaded, shared or submitted to our website or app by any user (“User Contributions“), and to any interactive services associated with it. The standards apply to each part of any User Contribution as well as to its whole and you must comply with the spirit of the following standards as well as the letter. You warrant that User Contributions that you upload to the website or app comply with these standards, and you indemnify us for any breach of that warranty.

</p>

      <p className="mb-4">
      Contributions must:<br />
      1. Contain any material which is defamatory of any person; <br />
      2. Contain any material which is obscene, offensive, hateful or inflammatory<br />
      3. Promote sexually explicit material or violence;<br />
      4. Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;<br />
      5. Infringe any copyright, database right, trademark or other intellectual property rights of any other person;<br />
      6. Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence;<br />
      7. Promote any illegal activity;<br /> 8. Be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety;<br /> 9. Contain contact details, last names, telephone numbers, street addresses or other identifying information of private individuals or public figures which compromises the privacy or security of anyone other than yourself;<br /> 
      10. Be likely to harass, upset, embarrass, alarm or annoy any other person;<br />
      11. Be used to impersonate any person, or to misrepresent your identity or affiliation with any person;<br /> 
      12. Place any links on the website where those links take users to unlawful material or material that otherwise contravenes these contribution standards;<br />
      13. Place on the website advertisements nor make commercial solicitations nor use the website for any commercial purposes (which would include using the website to promote or encourage the sale of your goods/services);<br />
      14. Give the impression that they emanate from us, if this is not the case; and/or <br />
      15. Advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse
</p>
      <p className="mb-4">
      We accept no responsibility for any statements, material (including User Contributions) or other submissions placed on our website or app by you or any third party, or for any loss or damage resulting from your breach of these contribution standards.

</p>
      <p className="mb-4">
      We reserve the right to edit or remove any material and User Contribution uploaded, shared or submitted using the website or app, for any reason whatsoever.
</p>
      <p className="mb-4">
      Notwithstanding our rights under these Terms of Use in relation to User Contributions, we do not undertake to monitor the submission or publication of User Contributions or users&apos; use of the website and app. If you find any content on our website or app to be in any way offensive, obscene, defamatory, racist, harmful, inaccurate, unlawful, illegal, infringing of any third party rights, deceptive in any way or otherwise in breach of our contribution standards, please notify us by email at contact@jpxhub.com and using the subject heading “Foul Content”
</p>
      <p className="mb-4">
      With respect to User Contributions that you upload, share or submit to our website, you grant us the royalty-free, perpetual, irrevocable, non-exclusive and fully sub-licensable right and licence to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display your User Contribution worldwide and/or to incorporate it in other works in any form or media (whether now known or invented in the future, which may include other internet sites, mobile, television and/or radio) and you acknowledge that your User Contribution may be made available with advertising and/or sponsorship.
</p>
      <p className="mb-4">
      You agree to waive any moral or similar rights in any jurisdiction that you may have in and to any of the User Contribution you upload, share or submit, even if the User Contribution is modified, altered or changed in a way which is not agreeable to you.
</p>
<h2 className="text-3xl font-semibold font-cabinet mb-3">Suspension and termination</h2>
      <p className="mb-4">
      We reserve the right to modify, suspend or discontinue any service offered by us or any part of it (including the availability of any features of or pages on the website or app) at any time and without notice or liability to you. We will determine, in our discretion, whether there has been a breach of these Terms of Use through your use of the website or app. When a breach has occurred, we may take such action as we deem appropriate.

</p>
<p className="mb-4">
Failure to comply with these Terms of Use constitutes a material breach and may result in our taking all or any of the following actions: <br />
      1. Immediate, temporary or permanent withdrawal of your right to use the website or app; <br />
      2. Immediate suspension or termination of your account;<br />
      3. Immediate, temporary or permanent removal of any posting or material uploaded by you to the website or app;<br />
      4. Issue of a warning to you;<br />
      5. Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach; <br />
      6. Further legal action against you; and/or<br /> 
      10.  disclosure of such information to law enforcement authorities as we reasonably feel is necessary.<br />
      </p>
      <p className="mb-4">
      We exclude liability for actions taken in response to breaches of these Terms of Use. The list of possible responses above are not exhaustive, and we may take any other action we reasonably deem appropriate

</p>
      <p className="mb-4">
      If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.

</p>
      <p className="mb-4">
      User Contributions that you have uploaded, shared or submitted using the website may still appear on the app or website even after your registration has terminated. We shall continue to have the right to use your User Contributions in accordance with the licence granted to us under these Terms of Use.

</p>
<h2 className="text-3xl font-semibold font-cabinet mb-3">Disclaimer</h2>
      <p className="mb-4">
      Accessing or using this website or app or its content in any way is done entirely at your own risk. You will be responsible for any loss or damage to any computer, device, software, systems or data resulting directly or indirectly from the use or inability to use the website or app or its content.

</p>
      <p className="mb-4">     
      We are under no obligation to provide uninterrupted access to this website or app. We reserve the right to restrict your access to this website or app at any time and for any reason.

</p>
      <p className="mb-4">      
      We do not guarantee that the contents of this website or app will be free of errors, bugs, worms, trojans or viruses or otherwise make any representations as to the quality or accuracy or completeness of the content available on the website including, but not limited to any price quotes, stock availability data or non-fraudulent representations. You are responsible for maintaining appropriate software on your computer or device to protect you from any such errors, bugs, worms, trojans or viruses.
</p>
      <p className="mb-4">      
      To the fullest extent permissible by law, we exclude any and all liability to you resulting from your use of the website or app or connected to these Terms of Use. This exclusion includes but is not limited to any type of damages, loss of data, income or profit or loss or damage to property belonging to you or third parties arising from the use of this website or its contents.

</p>
      <p className="mb-4">Nothing in these Terms of Use is intended to limit our liability to you for death or personal injury resulting from our negligence or that of our employees or agents.</p>
      <h2 className="text-3xl font-semibold font-cabinet mb-3">Links to third party websites</h2>
      <p className="mb-4">This website may provide hyperlinks to websites or other online resources under the control of third parties. Any such links are provided solely for your convenience. These third-party websites and resources have their own terms of use and privacy policies which you will need to comply with. We have no control over the contents of these third-party websites or resources and we are not responsible for the availability or contents of any linked websites and do not endorse them in any way.</p>
      <h2 className="text-3xl font-semibold font-cabinet mb-3">Links from third party websites</h2>
      <p className="mb-4">We welcome &apos;hot links&apos; and deep-links to the website, by which we mean that you may include a link to any page of the website on other websites, provided that you do not use such link in any way which would imply partnership, affiliation, endorsement or sponsorship of any service or product or which may bring us or the other brands in our group into disrepute. You may not display the Content or allow it to be displayed surrounded or framed or otherwise surrounded by material not originating from us without our consent. If you would like to license our material, please email contact@jpxhub.com. We reserve the right to withdraw any such permission at any time.</p>
      <h2 className="text-3xl font-semibold font-cabinet mb-3">Personal Data</h2>
      <p className="mb-4">We take your privacy and the protection of your data very seriously. When registering as a member of the website or app or interacting with other services offered by us via the website it may be necessary for you to provide some personal information to us. We will keep your personal information secure and will use it only in accordance with our Privacy Policy. All personal information held by us will be held in accordance with the terms of applicable data protection laws.</p>
      <h2 className="text-3xl font-semibold font-cabinet mb-3">Entire agreement</h2>
      <p className="mb-4">These Terms of Use are the entire agreement between us and you in relation to your use of the website, and supersede any and all prior terms, conditions, warranties or representations in respect of such use to the fullest extent permitted by law. If any term of these Terms of Use is held to be invalid or unenforceable, the remainder of these Terms of Use shall remain valid and enforceable.</p>
      <h2 className="text-3xl font-semibold font-cabinet mb-3">Applicable law</h2>
      <p className="mb-4">These Terms of Use will be governed by the English law and any disputes will be subject to the exclusive jurisdiction of the England courts.</p>
      <p className="mb-4">Last updated: 20 December 2024</p>
    </div>
    </div>
  );
};

export default TermsOfUse;
