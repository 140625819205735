import React, { useState } from 'react';
import FeatureCard from './FeatureCard';
import BenefitCard from './BenefitCard';
import StepCard from './StepCard';
import PricingCard from './PricingCard';
import ContactForm from './ContactForm';
import SignupForm from '../signup/SignupForm';
import { useNavigate } from 'react-router-dom';


const StartPage = () =>{
  const navigate = useNavigate();
    const [isSignUpOpen, setIsSignUpOpen] = useState(false);

    const handleOpenSignUp = () => {
        navigate('/Register');
    };

    const handleCloseSignUp = () => {
        setIsSignUpOpen(false);
    };

    

    return(
        <>
        <div className='text-center bg-[#2d2d2d] px-8 pt-12'>
            <h1 className='text-white font-semibold text-6xl pt-6 font-cabinet'>Empower your business <br />  to go global.</h1>
            <button
  className='bg-[#0344DC] py-4 px-6 rounded-lg text-white font-semibold mt-10 font-archivo' onClick={handleOpenSignUp}>Create Business Account</button>
            <div className='text-center'><img style={{display:'inline'}} src="/globe.png" alt="globe"/></div>
        </div>
        <div className="bg-[#f5f5f5] py-12 px-6">
  {/* Section Title */}
  <h2 className="text-center text-4xl mb-4 font-semibold font-cabinet">Our features</h2>
  <p className="text-center text-lg text-[#0E0F0C] font-archivo">
    Tailored Solutions Crafted for Your Global Ambitions.
  </p>

  {/* Features Grid */}
  <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-5xl mx-auto items-stretch">
  {/* First Row (3 Cards) */}
  <FeatureCard
    title="Business Accounts"
    description="Open foreign and domestic business accounts in minutes and effortlessly receive local and international payments."
    icon="/user.png"
    bgColor="bg-[#FFD964]"
    className="flex flex-col h-full"
  />
  <FeatureCard
    title="Instant Payments"
    description="Experience the speed and convenience of instant payments."
    icon="/pay.png"
    bgColor="bg-white"
    className="flex flex-col h-full"
  />
  <FeatureCard
    title="Receive Payments"
    description="Effortlessly accept payments from customers and suppliers worldwide."
    icon="/rec-pay.png"
    bgColor="bg-[#FFD964]"
    className="flex flex-col h-full"
  />

  {/* Second Row (2 Cards Centered in Desktop, Full Width in Mobile) */}
  <div className="lg:col-span-3 flex flex-col md:flex-row justify-center gap-6">
    <div className="w-full md:w-[320px] lg:ml-auto">
      <FeatureCard
        title="Swap Currencies"
        description="Experience the speed and convenience of instant currency conversions."
        icon="/swap.png"
        bgColor="bg-white"
        className="flex flex-col h-full min-h-[250px]"
      />
    </div>
    <div className="w-full md:w-[320px] lg:mr-auto">
      <FeatureCard
        title="Currency Exchange"
        description="Get the best rates on your foreign currency exchange."
        icon="/exchange.png"
        bgColor="bg-[#FFD964]"
        className="flex flex-col h-full min-h-[250px]"
      />
    </div>
  </div>
</div>









</div>

<div className="bg-[#f5f5f5] py-16 px-6">
  <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12">
    {/* Left Content - Heading & Description */}
    <div>
    <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-gray-900 font-cabinet break-words md:w-[500px] lg:w-[600px] leading-snug md:leading-tight">
  Everything you need to grow your global business
</h2>

      <p className="text-[#0E0F0C] font-archivo mt-4">
        We empower your business to navigate the complexities of global trade and unlock new opportunities for growth.
      </p>
    </div>

    {/* Right Content - Alternating Benefit Cards (Right → Left → Right → Left) */}
    <div className="flex flex-col space-y-4">
      <div className="max-w-[350px] ml-auto">
        <BenefitCard text="Simple and transparent pricing" />
      </div>
      <div className="max-w-[350px] mr-auto">
        <BenefitCard text="Zero setup fees" />
      </div>
      <div className="max-w-[350px] ml-auto">
        <BenefitCard text="A full suite of features out of the box" />
      </div>
      <div className="max-w-[350px] mr-auto">
        <BenefitCard text="No frills No hidden fees" />
      </div>
    </div>
  </div>
</div>



    <div className="bg-[#f5f5f5] py-16 px-6">
  <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12">
    {/* Left Content - Steps with Diagonal Layout */}
    <div className="relative flex flex-col space-y-6">
      <div className="max-w-[350px]">
        <StepCard number="1" text="Sign up to complete your identity and business verification." />
      </div>
      <div className="max-w-[350px] ml-auto">
        <StepCard number="2" text="Get access to your account and start transacting." />
      </div>
    </div>

    {/* Right Content - Heading & CTA */}
    <div className="flex flex-col items-start space-y-4">
      <div>
      <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-gray-900 font-cabinet break-words md:w-[500px] lg:w-[600px] leading-snug md:leading-tight">
  How to Apply for a SP Business Account
</h2>

        <p className="text-[#0E0F0C] font-archivo mt-4">
          Follow these simple steps to create your account and unlock a world of business opportunities:
        </p>
        <button
  className='bg-[#0344DC] py-4 px-6 font-archivo rounded-lg text-white font-semibold mt-4' onClick={handleOpenSignUp} > Create Business Account </button>

      </div>
    </div>
  </div>
</div>

<div className="bg-[#f5f5f5] py-16 px-6">
      <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        {/* Left Content - Heading & Description */}
        <div>
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-gray-900 font-cabinet break-words md:w-[500px] lg:w-[600px] leading-snug md:leading-tight">
  Flexible pricing based on your business needs
</h2>

          <p className="text-[#0E0F0C] font-archivo mt-4">
            Low or high business risk level will be assigned by the SP team upon completion of the 
            due diligence procedures applicable to our clients.
          </p>
        </div>

        {/* Right Content - Pricing Card */}
        <PricingCard />
      </div>
    </div>

    <div className="relative bg-[#2D2D2D] py-16 px-6">
    <div className="absolute top-0 left-0 w-14 h-14 bg-[#0344DC] rounded-br-full"></div>

{/* Right Center Semi-Circle */}
<div className="absolute top-1/2 right-0 w-12 h-24 bg-[#0344DC] rounded-l-full transform -translate-y-1/2"></div>
      <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        {/* Left Content - Heading & Subtext */}
        <div>
          <h2 className="text-5xl font-semibold text-white font-cabinet">
            Have a Question?
          </h2>
          <p className="text-white mt-4">
            We are here to help! Contact us today.
          </p>
        </div>

        {/* Right Content - Contact Form */}
        <ContactForm />
      </div>
    </div>


    <SignupForm isOpen={isSignUpOpen} onClose={handleCloseSignUp}  />


        </>
    )
}

export default StartPage;