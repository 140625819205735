import React from "react";

const CookiePolicy = () => {
  return (
    <div className="pl-12 pr-12 bg-[#f5f5f5] font-archivo text-[#0E0F0C] pt-4">
      <div className="border-b border-[#EBEBEB] pb-8">
        <h1 className="text-5xl font-semibold font-cabinet mb-8 pt-4">Cookie Policy</h1>
        <p>
          This policy provides information about how and when we use cookies for these purposes. 
          Capitalized terms used in this policy but not defined have the meaning set forth in our 
          <span className="font-semibold"> Privacy Policy</span>, which also includes additional 
          details about the collection and use of information by sP.
        </p>

        <h2 className="text-3xl font-semibold font-cabinet mb-3">What is a cookie?</h2>
        <p className="mb-4">
          Cookies are small text files sent by us to your computer or mobile device. They are 
          unique to your account or your browser. Session-based cookies last only while your 
          browser is open and are automatically deleted when you close your browser. Persistent 
          cookies last until you or your browser delete them or until they expire. 
          To find out more about cookies, visit 
          <a href="http://www.allaboutcookies.org/" className="text-blue-600 underline ml-1">
            www.allaboutcookies.org
          </a>.
        </p>

        <h2 className="text-3xl font-semibold font-cabinet mb-3">Does sP use cookies?</h2>
        <p className="mb-6">
          Yes. We use cookies and similar technologies like single-pixel GIFs and web beacons. 
          We use both session-based and persistent cookies. Our system sets and accesses our 
          own cookies on the domains operated by us and our corporate affiliates (collectively, 
          the &ldquo;Sites&rdquo;). In addition, we use third-party cookies, like Google Analytics.
        </p>

        <h2 className="text-3xl font-semibold font-cabinet mb-3">How is sP using cookies?</h2>
        <p className="mb-4">
          Some cookies are associated with your account and personal information to remember 
          that you are logged in. Other cookies are not tied to your account but are unique 
          and allow us to carry out analytics and customization, among other similar things.
        </p>

        <ul className="mb-4 list-disc ml-6">
          <li><strong>Authentication</strong> – If you&apos;re signed into our Services, cookies help us show you the right information.</li>
          <li><strong>Security</strong> – We use cookies to support security features and detect malicious activity.</li>
          <li><strong>Preferences &amp; Services</strong> – Cookies help us remember your settings, preferred language, and user preferences.</li>
          <li><strong>Marketing</strong> – We may use cookies for marketing campaigns and tracking ad performance.</li>
          <li><strong>Analytics</strong> – Cookies help us improve our Services by tracking user behavior and interactions.</li>
        </ul>

        <h2 className="text-3xl font-semibold font-cabinet mb-3">How are cookies used for advertising purposes?</h2>
        <p className="mb-4">
          Cookies and other ad technology such as beacons, pixels, and tags help us market 
          more effectively to users that we and our partners believe may be interested in sP. 
          They also help provide us with aggregated auditing, research, and reporting.
        </p>

        <h2 className="text-3xl font-semibold font-cabinet mb-3">
          What can you do if you don&apos;t want cookies to be set or want them to be removed?
        </h2>
        <p className="mb-4">
          Most browsers allow you to manage cookies. You can set up rules to allow or block 
          cookies from specific sites. Browser manufacturers provide help pages related to 
          cookie management, such as:
        </p>

        <ul className="mb-4 list-disc ml-6">
          <li><a href="https://support.google.com/chrome/answer/95647" className="text-blue-600 underline">Google Chrome</a></li>
          <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" className="text-blue-600 underline">Internet Explorer</a></li>
          <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" className="text-blue-600 underline">Mozilla Firefox</a></li>
          <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" className="text-blue-600 underline">Safari</a></li>
          <li><a href="https://help.opera.com/en/latest/web-preferences/" className="text-blue-600 underline">Opera</a></li>
        </ul>

        <h2 className="text-3xl font-semibold font-cabinet mb-3">Does sP respond to Do Not Track signals?</h2>
        <p className="mb-4">
          Our services do not collect personal information about your online activities over time 
          and across third-party websites or services. Therefore, &ldquo;Do Not Track&rdquo; signals 
          transmitted from web browsers do not affect our Sites or Services.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicy;
