import React from 'react';
import PropTypes from 'prop-types';

const PricingItem = ({ title, amount, note }) => {
  return (
    <div className="bg-[#FFEDB5] p-3 rounded-md">
      <p className="text-[#6F6F6F] text-sm font-archivo">{title}</p>
      <p className="text-[#6F6F6F] font-archivo">
        <span className="font-bold">{amount}</span>
        {note && <span className="font-normal text-sm font-archivo"> {note}</span>}
      </p>
    </div>
  );
};

// ✅ PropTypes Validation
PricingItem.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  note: PropTypes.string, // Optional text
};

export default PricingItem;
