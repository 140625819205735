import React from 'react';
import PropTypes from 'prop-types';

const FeatureCard = ({ title, description, icon, bgColor }) => {
  return (
    <div className={`${bgColor} p-6 rounded-lg shadow-md relative flex flex-col justify-between h-full`}>
      {/* Icon */}
      <div className="flex items-center">
        <img src={icon} alt={title} className="w-10 h-10" />
      </div>

      {/* Title */}
      <h3 className="text-3xl font-semibold mt-4 text-[#0344DC] font-cabinet whitespace-nowrap">{title}</h3>

      {/* Description with flex-grow to push spacing to bottom */}
      <p className="text-[#2D2D2D] mt-2 flex-grow font-archivo">{description}</p>

      {/* Decorative Corner */}
      <div className="absolute bottom-0 right-0 w-10 h-10 bg-blue-800 rounded-tl-full"></div>
    </div>
  );
};

// ✅ Add PropTypes validation
FeatureCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};

export default FeatureCard;
