import React from 'react';
import { Link } from "react-router-dom";

const FrontFooterLinks = () => {
  return (
    <div className="w-full md:w-1/2 flex flex-wrap">
      {/* Legal Section (25%) */}
      <div className="w-full md:w-1/2">
        <h3 className="text-md text-[#0E0F0C] font-archivo">Legal</h3>
        <ul className="text-[#6F6F6F] font-archivo text-sm mt-2 space-y-1">
  <li>
    <Link to="/privacy-policy" className="hover:underline">
      Privacy Policy
    </Link>
  </li>
  <li>
    <Link to="/terms-of-use" className="hover:underline">
      Terms of Use
    </Link>
  </li>
  <li>
    <Link to="/cookie-policy" className="hover:underline">
      Cookies Policy
    </Link>
  </li>
  <li>
    <Link to="/prohibited-list" className="hover:underline">
      Prohibited List
    </Link>
  </li>
</ul>

      </div>

      {/* Contact Section (25%) */}
      <div className="w-full md:w-1/2 mt-2 md:mt-0 font-archivo">
  <h3 className="text-md text-[#0E0F0C] font-archivo">Contact</h3>
  <p className="text-gray-600 text-sm mt-2">128 City Road, London, EC1V2NX</p>
  <p className="text-gray-600 text-sm">Contact@jpxhub.com</p>        
</div>

    </div>
  );
};

export default FrontFooterLinks;
