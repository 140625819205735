import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import InputField from './InputField';
import { Constants } from '../../lib/const/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useId } from 'react';
import { PrimaryButton } from '../../components/button';

const SignInForm = () => {
  const navigate = useNavigate();
  const deviceId = useId();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    await axios.post(Constants.BASE_URL + 'API-FX-120-Login', {
      email: username,
      password: password
    }, {
      headers: {
        'fx_key': Constants.SUBSCRIPTION_KEY,
      }
    }).then(resp => {
      // console.log(JSON.stringify(resp.data));
      loadAppDefault();
      loadcountries();
      if (resp.data.data.registration_step == 'account_preview') {
        onHandle(resp.data.data.email)
        setAsyncData("login_id", JSON.stringify(resp.data.data.id))
        setAsyncData("login_full_name", resp.data.data.full_name)
        setAsyncData("login_email", resp.data.data.email)
        setAsyncData("login_phone", resp.data.data.phone)
        setAsyncData("login_date_of_birth", resp.data.data.date_of_birth)
        setAsyncData("login_country_code", resp.data.data.country_code)
        setAsyncData("login_country_id", JSON.stringify(resp.data.data.country_id))
        setAsyncData("login_country_name", resp.data.data.country_name)
        setAsyncData("login_nationality", resp.data.data.nationality)
        setAsyncData("login_registration_step", resp.data.data.registration_step)
        setAsyncData("login_is_banking_user", JSON.stringify(resp.data.data.is_banking_user))
        setAsyncData("login_status", resp.data.data.status)
        setAsyncData("login_yoti_status", resp.data.data.yoti_status)
        setAsyncData("login_workspaces", JSON.stringify(resp.data.data.workspaces))
        setAsyncData("login_workspaces_id", JSON.stringify(resp.data.data.workspaces[0].id))
        setAsyncData("login_token", JSON.stringify(resp.data.token))
        setAsyncData("login_address", JSON.stringify(resp.data.data.addresses[0].address + resp.data.data.addresses[0].street))
        setAsyncData("login_postcode", JSON.stringify(resp.data.data.addresses[0].postcode))
        setAsyncData("login_city", JSON.stringify(resp.data.data.addresses[0].city))        
        console.log(resp.data.default_bank);
        if (resp.data.default_bank.length > 0 && resp.data.default_bank[0] != 'CC as Service') {
          setAsyncData("defaultBank", resp.data.default_bank[0])
          setAsyncData("clearBankCustomerId", resp.data.clear_bank_data[0].customer_id)
          setAsyncData("clearBankCustomerBankCode", resp.data.clear_bank_data[0].meta.bank[0].BankCode)
          setAsyncData("clearBankCustomerIBAN", resp.data.clear_bank_data[0].meta.bank[0].IBAN)
          setAsyncData("clearBankCustomerWalletId", resp.data.clear_bank_data[0].meta.bank[0].WalletId)
          setAsyncData("clearBankCustomerSWIFTCode", resp.data.clear_bank_data[0].meta.bank[0].SWIFTCode)
          setAsyncData("clearBankCustomerAccountName", resp.data.clear_bank_data[0].meta.bank[0].AccountName)
          setAsyncData("clearBankCustomerWalletNumber", resp.data.clear_bank_data[0].meta.bank[0].WalletNumber)
          setAsyncData("clearBankCustomerSortCode", resp.data.clear_bank_data[0].meta.bank[0].sortCode)
          setAsyncData("clearBankCustomerServiceProviderAccountId", resp.data.clear_bank_data[0].meta.bank[0].ServiceProviderAccountId)
        }
        else {
          setAsyncData("defaultBank", "CC as Service")
          setAsyncData("clearBankCustomerId", "")
        }
        setAsyncData("kyc_submitted", "true");
        setLoading(false);
        setAsyncData("login_id", JSON.stringify(resp.data.data.id))
        console.log("rrrrr" + resp.data.data.id);
        navigate('/')
      }
      else {
        dropscreens(resp.data.data.id, password)
        setLoading(false);
      }
      setLoading(false);
    }).catch(err => {
      console.log(JSON.stringify(err.response));
      setError(err.response.data.message)
      // setPin("");
      setLoading(false);
    })
  };

  const loadcountries = async () => {
    await axios.get(Constants.BASE_URL + "API-FX-102-Country", {
      headers: {
        fx_key: Constants.SUBSCRIPTION_KEY
      }
    }).then(response => {
      setAsyncData('countries', JSON.stringify(response.data.data));
    }).catch(error => {
      console.log("countr" + error.response.data);
    })
  }
  const loadAppDefault = async () => {
    await axios.get(Constants.BASE_URL + "API-FX-100-App", {
      headers: {
        fx_key: Constants.SUBSCRIPTION_KEY
      }
    }).then(response => {
      setAsyncData('transfer_reasons', JSON.stringify(response.data.data.transfer_reasons));
    }).catch(error => {
      console.log("app" + error.response);
    })
  }
  const dropscreens = async (userid, pin) => {
    await axios.get(Constants.BASE_URL + "API-FX-160-DROPSCREENDETAILS?user-id=" + userid, {
      headers: {
        fx_key: Constants.SUBSCRIPTION_KEY
      }
    }).then(dropresp => {
      var pagename = "";
      for (var d = 0; d < dropresp.data.length; d++) {
        if (dropresp.data[d].screen_name == "NATIONALITY_2") {
          setAsyncData('userid', JSON.stringify(userid))
          setAsyncData('user_full_name', dropresp.data[d].meta.first_name)
          setAsyncData('user_email', dropresp.data[d].meta.email)
          setAsyncData('user_phone', dropresp.data[d].meta.phone)
          setAsyncData('user_first_name', dropresp.data[d].meta.first_name)
          setAsyncData('user_middle_name', dropresp.data[d].meta.middle_name)
          setAsyncData('user_last_name', dropresp.data[d].meta.last_name)
          setAsyncData('user_country_code', dropresp.data[d].meta.country_code)
          setAsyncData('user_country_id', dropresp.data[d].meta.country_id)
          setAsyncData('registrationToken', dropresp.data[d].meta.registrationToken)
          setAsyncData('deviceid', deviceId)
          setAsyncData('firstName', dropresp.data[d].meta.first_name)
          setAsyncData('middleName', dropresp.data[d].meta.middle_name)
          setAsyncData('lastName', dropresp.data[d].meta.last_name)
          setAsyncData('registrationType', dropresp.data[d].meta.registrationType)
          pagename = 'VerifyPhone';
        }
        if (dropresp.data[d].screen_name == "MOBILE_OTP_3") {
          pagename = 'VerifyEmail';
        }
        if (dropresp.data[d].screen_name == "EMAIL_OTP_4") {
          pagename = 'SumSubRegister';
        }
        if (dropresp.data[d].screen_name == "DOB_ADDRESS_5") {
          // setAsyncData("yotisessionID", dropresp.data[d].meta.yotisessionID);
          // setAsyncData("yotisessionToken", dropresp.data[d].meta.yotisessionToken);
          // setAsyncData("yotiurl", dropresp.data[d].meta.yotiurl);
          setAsyncData("enterPin", pin);
          if (dropresp.data[d].meta.regType == 'personal')
            pagename = 'ApplicationPreview';
          else
            pagename = 'BusinessDetails';
        }
        if (dropresp.data[d].screen_name == "BUSINESS_DETAILS_6") {
          setAsyncData('activity', dropresp.data[d].meta.activity);
          setAsyncData('turnover', dropresp.data[d].meta.turnover);
          setAsyncData('selectedAddress', dropresp.data[d].meta.selectedAddress);
          setAsyncData('selectedcompanyName', dropresp.data[d].meta.selectedcompanyName);
          setAsyncData('selectedcompanyNumber', dropresp.data[d].meta.selectedcompanyNumber);
          setAsyncData('selectedcompanyAddress', dropresp.data[d].meta.selectedcompanyAddress);
          setAsyncData('selectedcompanyPostalCode', dropresp.data[d].meta.selectedcompanyPostalCode);
          setAsyncData('selectedcompanyStatus', dropresp.data[d].meta.selectedcompanyStatus);
          setAsyncData('selectedcompanyCountry', dropresp.data[d].meta.selectedcompanyCountry);
          setAsyncData('selectedcompanyStreet', dropresp.data[d].meta.selectedcompanyStreet);
          setAsyncData('selectedcompanyHouseNumber', dropresp.data[d].meta.selectedcompanyHouseNumber);
          pagename = 'AboutBusiness';
        }
        if (dropresp.data[d].screen_name == "ABOUT_BUSINESS_7") {
          setAsyncData('businessWebsite', dropresp.data[d].meta.website);
          setAsyncData('businessEmail', dropresp.data[d].meta.email);
          setAsyncData('businessPhone', dropresp.data[d].meta.phone);
          setAsyncData('businessAbout', dropresp.data[d].meta.about);
          pagename = 'BusinessAddress';
        }
        if (dropresp.data[d].screen_name == "BUSINESS_ADDRESS_8") {
          pagename = 'SelectOfficer';
        }
        if (dropresp.data[d].screen_name == "SELECT_OFFICER_9") {
          setAsyncData("enterPin", pin);
          pagename = 'ApplicationPreview';
        }
        if (dropresp.data[d].screen_name == "YOTI_COMPLETED_6") {
          setAsyncData("enterPin", pin);
          pagename = 'ApplicationPreview';
        }
        if (pagename != "" && pagename != null) {
          navigate('/' + pagename,{state:{pin:password}})
        }
      }
      setLoading(false);
    }).catch(droperr => {
      console.log(droperr.response.data);
      setLoading(false);
    })
  }
  const setAsyncData = async (key, value) => {
    sessionStorage.removeItem('yotiurl')
    sessionStorage.removeItem('totalprocessingurl')
    sessionStorage.setItem(key, value);
  }

  const onHandle = async(email) =>{
    // OneSignal.User.addTag('login',email)
    console.log(email);
    
  }

  return (
    <div className="w-full max-w-md md:max-w-lg p-8 relative">
      <h2 className="text-center text-4xl font-cabinet font-semibold text-[#0E0F0C] mb-8">
        Login to Your Account
      </h2>

      {/* Form (Disable default validation) */}
      <form noValidate className="space-y-6">
        {/* Email Field */}
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <div className="relative">
          <label className="block text-md font-medium text-[#0E0F0C] text-archivo mb-2">
            Enter Email Address
          </label>
          <InputField
            type="email"
            placeholder="Enter email address"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>

        {/* PIN Field */}
        <div className="relative">
          <label className="block text-md font-medium text-[#0E0F0C] text-archivo mb-2">
            Enter PIN
          </label>
          <InputField
            type="password"
            placeholder="Enter PIN"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {/* Submit Button */}
        <PrimaryButton
          onClick={handleLogin}
          label={"Login"}
          loading={loading}
        />
      </form>

      {/* Footer Links */}
      <div className="text-center mt-6 text-sm text-[#0E0F0C] text-archivo">
        Don’t have an account?{' '}
        <button 
          onClick={()=>navigate('/Register')} 
          className="text-[#0344DC] font-medium underline"
        >
          Create Account
        </button>
      </div>

      <div className="text-center mt-2 text-sm">
        <button onClick={()=>navigate('/ForgotPin')} className="text-[#0344DC] font-semibold text-archivo underline">
          Forgot Password?
        </button>
      </div>
    </div>
  );
};

// ✅ PropTypes Validation
// SignInForm.propTypes = {
  // onClose: PropTypes.func.isRequired,
  // onOpenSignUp: PropTypes.func.isRequired, 
// };

export default SignInForm;
